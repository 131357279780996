.maintenance {

    &__container {
        max-width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }

    &__text-small {
        font-size: toRem(24);
        letter-spacing: 3px;
        text-transform: capitalize;
        font-weight: 500;
    }

    &__text-big {
        font-size: toRem(40);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 5px;
        padding: 10px 15px;
        border-radius: 25px;
        background-color: #000;
        color: #ffffff;
        display: inline-block;
    }

    &__image {
        margin-top: 50px;
        width: 400px;
        height: 400px;
        animation-name: updown;
        animation-duration: 6s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        
        img{
            width: 100%;
            height: 100%;
        }
    }
}
@keyframes updown{
    0%{
        transform: translate(0,0);
    }
    25%{
        transform: translate(0,-40px);
    }
    50%{
        transform: translate(0,0px);
    }
    75%{
        transform: translate(0,40px);
    }
    100%{
        transform: translate(0,0);

    }
}

