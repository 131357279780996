.advertisement-config {
    padding: 20px 0px 20px;
    &__container {
        padding-left: 300px;
    }

    &__title {
    }
    &__create-adv {
        margin-top: 20px;
    }
    &__create-view {
        margin-top: 20px;
    }
    &__info{
        margin-top: 30px;
        
    }
    &__table {
        margin-top: 20px;
    }
    &__control-row {
        margin-top: 20px;
    }
}

@media(hover:hover){
    .create-view{
        &:hover{
            box-shadow: #000 0px 2px 8px 0px;
            transition: all .3s ease-in-out;
        }
    }
    
}
