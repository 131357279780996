.stat {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2,250px);
    column-gap: 50px;
    row-gap: 30px;
    justify-content: center;

    &__item {
        padding: 15px 20px;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 10px;
        border: 1px solid #000;
        box-shadow: #000 0px 2px 8px 0px;
        border-radius: 25px;
        
    }

    &__title {
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        text-align: center;
        
    }

    &__amount {
        font-size: toRem(16);
        font-weight: 500;
        line-height: 100%;
        text-align: center;
    }
}