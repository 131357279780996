.users-admin {
    padding: 20px 0px 20px;
    &__container {
        padding-left: 300px;
    }

    &__title {
    }

    &__table {
        margin-top: 20px;
    }

    &__paging {
        margin-top: 20px;
    }
    
    & .email{
        flex: 0 0 250px;
        text-align-last: left;
        padding-left: 10px;
        text-overflow: ellipsis;
        padding-right: 5px;
    }
    & .actions{
        flex: 0 0 70px;
        text-overflow: ellipsis;
    }
    & .total-sum{
        flex: 0 0 70px;
        text-overflow: ellipsis;
    }
}

