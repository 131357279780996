//загальні стилі
.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: -100%;
    z-index: 10;
    background-color: rgba(30, 33, 42, 0.904);
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    transition: all 0.3s ease;
    &-active{
        left: 0;
        visibility: visible;
        transition: all 0.3s ease;
    }

    &__body {
        width: 700px;
        background-color: #fff;
        border-radius: 10px;
        padding: 20px 15px;
        overflow: auto;
        position: relative;
        
    }
    &__close{
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }   

    }
    &__title {
        padding-right: 50px;
    }
    &__wrapper{
        margin-top: 15px;

    }
    &__buttons{
        margin-top: 15px;
        display: flex;
        gap: 20px;
        align-items: center;

    }
    &__btn-ok,&__btn-cancel{
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
    }
    &__btn-cancel{

    }
}
//alert popup
.alert-popup {

    &__body {
        position: relative;
        width: auto;
        max-width: 700px;
        display: inline-block;
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }        
    }

    &__wrapper {
        padding-right: 50px;
        display: inline-block;
        font-size: toRem(18);
        font-weight: 500;
    }
}


// стилі попапа додавання моделі
.create-add-popup {

    &__body {
        position: relative;
        
    }

    &__title {
    }
    &__close{
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }

    }

    &__wrapper {
        margin-top: 30px;
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: space-between;
        
    }

    &__input {
        width: 100%;
        input{
            display: block;
            width: 100%;
            padding: 10px;
            font-size: toRem(14);
            line-height: 100%;
            border: 1px solid #000;
            color: $mainColor;
            transition: all 0.5s ease;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: 8px;
        }
    }

    &__btn-continue {
        padding: 10px 15px;
        font-size: toRem(16);
        font-weight: 500;
        line-height: 100%;
        border: 1px solid #000;
        
        border-radius: 15px;
        transition: all .3s ease-in-out;

    }
    &__buttons {
        margin-top: 15px;
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__btn-ok {
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
    }

    &__btn-cancel {
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
        
    }
}
// стилі попапа архів
.archive-popup {

    &__body {
        width: 500px;
        position: relative;
    }

    &__title {

    }
    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }        
    }
    &__text {
        margin-top: 15px;
        font-size: toRem(16);
        line-height: 100%;
    }

    &__buttons {
        margin-top: 15px;
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__btn-ok {
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
    }

    &__btn-cancel {
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
        
    }
}
//стилі створення view
.create-view {

    &__popup {
    }

    &__body {
        position: relative;
        width: 800px;
    }

    &__title {
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }
    }

    &__wrapper {
        margin-top: 15px;
        display: flex;
        gap: 20px;
    }

    &__presentation {
        width: 350px;
    }
    &__data {
    }
    &__create {
        width: 150px;
        border: 1px solid #000;
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        
    }
}
.data-create-view {

    &__body {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        /* row-gap: 15px; */
        height: 100%;
        width: 100%;
        align-content: space-between;
    }
    /* &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }        
    } */

    &__warninng {
        width: 350px;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        box-shadow: #000 0px 2px 8px 0px;

        svg{
            width: 60px;
            height: 60px;
        }
    }

    &__photo-upload {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        width: 180px;
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
        svg{
            width: 20px;
            height: 20px;
        }
        input{
            display: none;
        }
    }

    &__name {
    }

    &__dectination-url {
    }

    &__trial-url {
    }

    &__about {
    }
}
.model-nickname {

    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 42px;
    }
}
.dectination-url {

    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 42px;
    }
}
.trial-url {

    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 42px;
    }
}

.about {
    &__textarea {
        display: block;
        width: 390px;
        max-width: 390px;        
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 100px;
        max-height: 100px;
    }
}
// редагування реклами попап
.edit-configuration {

    &__popup {
    }

    &__body {
        position: relative;
        width: 800px;
    }

    &__title {
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }
    }

    &__wrapper {
        margin-top: 15px;
        display: flex;
        gap: 20px;
    }

    &__presentation {
        width: 350px;
    }

    &__data {
    }

    &__save {
        width: 150px;
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
    }
}
/* .data-create-view {

    &__body {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        row-gap: 15px;
        width: 100%;
    }

    &__warninng {
        width: 350px;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        box-shadow: #000 0px 2px 8px 0px;

        svg{
            width: 60px;
            height: 60px;
        }
    }

    &__photo-upload {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        width: 180px;
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
        svg{
            width: 20px;
            height: 20px;
        }
        input{
            display: none;
        }
    }

    &__name {
    }

    &__dectination-url {
    }

    &__trial-url {
    }

    &__about {
    }
} */
.model-nickname {

    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 42px;
    }
}
.dectination-url {

    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 42px;
    }
}
.trial-url {

    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 42px;
    }
}

// стилі попапа делейт
.delete-popup{
    &__body {
        position: relative;
        width: 500px;
    }

    &__title {

    }
    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }        
    }

    &__text {
        margin-top: 15px;
        font-size: toRem(16);
        line-height: 100%;
    }

    &__buttons {
        margin-top: 15px;
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__btn-ok {
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
    }

    &__btn-cancel {
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
    }
}
//стилі попапа view-card
.view-card {

    &__body {
        position: relative;
        width: 400px;
        padding: 20px;
    }

    &__title {
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }
    }

    &__presentation {
        margin-top: 20px;
        max-width: 360px;
    }
}
//стилі попапа chat-creation
.create-chat {

    &__body {
        position: relative;
    }

    &__title {
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }
    }

    &__wrapper {
        margin-top: 30px;
        display: flex;
        gap: 15px;
        align-items: flex-end;
        justify-content: space-between;
        width: 100%;
    }
    &__data-body{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        row-gap: 15px;
    }
    &__input {
        input{
            display: block;
            width: 100%;
            padding: 10px;
            font-size: toRem(14);
            line-height: 100%;
            border: 1px solid #000;
            color: $mainColor;
            transition: all 0.5s ease;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: 8px;
        }
    }
    &__textarea{
        textarea{
            display: block;
            padding: 10px;
            width: 100%;
            max-width: 100%;
            min-width:100%;
            height: 100px;
            max-height: 100px;
            padding: 10px;
            font-size: toRem(14);
            line-height: 100%;
            border: 1px solid #000;
            color: $mainColor;
            transition: all 0.5s ease;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: 8px;
        }
    }
    &__buttons {
        margin-top: 15px;
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__btn-ok {
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
    }

    &__btn-cancel {
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
    }
    /* &__btn-continue {
        padding: 10px 15px;
        font-size: toRem(16);
        font-weight: 500;
        line-height: 100%;
        border: 1px solid #000;
        
        border-radius: 15px;
        transition: all .3s ease-in-out;
    } */
}
//стилі попапа додавання моделі адміном
.create-adv-admin {

    &__body {
        position: relative;
        width: 800px;
    }

    &__title {
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }
    }

    &__wrapper {
        margin-top: 15px;
        display: flex;
        gap: 20px;
    }

    &__presentation {
        width: 350px;
    }

    &__data {
        & .gender-select__select-header, 
        & .custom-select__select-box,
        & .instagram__input,
        & .tiktok__input,
        & .twitter__input{
            height: 42px;
        }
    }
    &__submit{
        width: 150px;
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
    }
}
.data-create-view {

    &__body {
        
    }

    &__photo-upload {
    }

    &__select {
    }

    &__instagram {
    }

    &__tiktok {
    }

    &__twitter {
    }

    &__name {
    }

    &__about {
    }
}
.instagram {

    &__input {
    }
}
.tiktok {

    &__input {
    }
}
.twitter {

    &__input {
    }
}
.model-nickname {

    &__input {
    }
}
.about {

    &__textarea {
    }
}
.create-view {

    &__create {
    }
}
//стилі попапу створення модера

.create-moderator {
    & .popup__body{
        width: 530px;
    }
    &__body {
        position: relative;        
    }

    &__title {
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }
    }

    &__wrapper {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(1,500px);
        gap: 30px;
    }

    &__input {
        input{
            display: block;
            width: 500px;
            padding: 10px;
            font-size: toRem(14);
            line-height: 100%;
            border: 1px solid #000;
            color: $mainColor;
            transition: all 0.5s ease;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: 8px;
            height: 40px;
        }
        & .password-item{
            &__input{
                height: 40px;
                padding: 10px 40px 10px 10px;
            }
            
        }
    }

    &__buttons {
        margin-top: 20px;
        display: flex;
        gap: 30px;
    }

    &__btn-ok {
        width: 100px;
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
    }

    &__btn-cancel {
        width: 100px;
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
    }
}
//стилі попапу підтвердження реклами менеджером
.approve-advertisement-one {

    &__body {
        position: relative;
        width: 800px;
    }

    &__title {
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }
    }

    &__wrapper {
        margin-top: 15px;
        display: flex;
        gap: 20px;

    }

    &__presentation {
        width: 350px;
        height: 100%;
    }
    &__data{
        width: 370px;
        & .gender-select__select-header, 
        & .custom-select__select-box,
        & .instagram__input,
        & .tiktok__input,
        & .twitter__input,
        & .model-nickname__input,
        & .dectination-url__input,
        & .trial-url__input,
        & .daily-budget__input,
        & .click-cost__input{
            height: 40px;
        }
        & .about__textarea{
            height: 80px;
            max-height: 80px;
            min-height: 80px;
            width: 370px;
            min-width: 370px;
            max-width: 370px;
        }

    }
}
.data-create-view {

    &__body {
        row-gap: 15px;
    }

    &__buttons {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        gap: 15px;
    }

    &__approve {
        padding: 5px 10px;
        width: 100px;
        border: 1px solid #000000;
        border-radius: 25px;
        svg{
            width: 25px;
            height: 25px;

        }
    }

    &__reject {
        padding: 5px 10px;
        width: 100px;
        border: 1px solid #000000;
        border-radius: 25px;
        svg{
            width: 25px;
            height: 25px;
        }
    }
}
//стилі попапу підтвердження реклами менеджером (слайдер)
.approve-advertisement-multi {

    &__body {
        position: relative;
        width: 900px;
    }

    &__title {
        margin-left: 70px;
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }
    }

    &__slide {
    }

    &__wrapper {
        margin-top: 15px;
        display: flex;
        gap: 20px;
    }

    &__presentation {
        width: 350px;
        height: 100%;
        min-height: 456px;
        max-height: 552px;
    }

    &__data {
        width: 370px;
        & .gender-select__select-header, 
        & .custom-select__select-box,
        & .instagram__input,
        & .tiktok__input,
        & .twitter__input,
        & .model-nickname__input,
        & .dectination-url__input,
        & .trial-url__input,
        & .daily-budget__input,
        & .click-cost__input{
            height: 40px;
        }
        & .about__textarea{
            height: 80px;
            max-height: 80px;
            min-height: 80px;
            width: 370px;
            min-width: 370px;
            max-width: 370px;
        }
    }
}
.multi-slide {
    display: flex;
    gap: 20px;
    &__arrow {
        svg{
            width: 50px;
            height: 50px;
            transition: transform .3s ease-in-out;
        }
        
    }
    &__disabled{
        svg{
            polyline{
                stroke: grey;
            }
        }
    }
}
//стилі попапу до давання транзакції
.add-transaction-admin {

    &__body {
        position: relative;
    }

    &__title {
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }
    }

    &__wrapper {
        margin-top: 15px;
        display: flex;
        gap: 20px;
        justify-content: space-between;
    }

    &__input {
        width: 100%;
        input{
            display: block;
            width: 100%;
            padding: 10px;
            font-size: toRem(14);
            line-height: 100%;
            border: 1px solid #000;
            color: $mainColor;
            transition: all 0.5s ease;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: 8px;
        }
    }

    &__btn-submit {
        padding: 10px 15px;
        font-size: toRem(16);
        font-weight: 500;
        line-height: 100%;
        border: 1px solid #000;        
        border-radius: 15px;
        transition: all .3s ease-in-out;
    }
    &__buttons {
        margin-top: 15px;
        display: flex;
        gap: 20px;
        align-items: center;
    }

    &__btn-ok {
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
    }

    &__btn-cancel {
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
        
    }
}
//стилі попару масової розсилки
.mass-mail-popup {

    &__body {
        position: relative;
    }

    &__title {
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }
    }

    &__wrapper {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    &__subject {
        input{
            display: block;
            width: 100%;
            padding: 10px;
            font-size: toRem(14);
            line-height: 100%;
            border: 1px solid #000;
            color: $mainColor;
            transition: all 0.5s ease;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: 8px;
        }
    }

    &__text {
        textarea{
            display: block;
            width: 100%;
            max-width: 100%; 
            min-width: 100%;
            max-height: 200px;  
            height: 200px;            
            min-height: 200px;
            padding: 10px 10px 10px 15px;
            font-size: toRem(16);
            line-height: 100%;
            border: 1px solid #000;
            color: $mainColor;
            transition: all 0.5s ease;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: 8px;
            
            
        }
    }
    &__file-upload{
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        width: 155px;
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border-radius: 25px;
        border: 1px solid #000;
        svg{
            width: 20px;
            height: 20px;
        }
        input{
            display: none;
        }
    }

    &__btn-send {
        width: 100px;
        padding: 10px 15px;
        font-size: toRem(16);
        font-weight: 500;
        line-height: 100%;
        border: 1px solid #000;        
        border-radius: 15px;
        transition: all .3s ease-in-out;
    }
}
//стилі попапу чату
.chat-popup {
    & .popup__body{
        width: 500px;
    }
    &__body {
        position: relative;
    }

    &__subject {
    }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }
    }

    &__chat {
        margin-top: 30px;
        padding: 10px;
        box-shadow: #000 0px 2px 8px 0px;
        border-radius: 10px;
        min-height: 400px;
        max-height: 400px;
        overflow-y: auto;
        &::-webkit-scrollbar{
            width: 7px;
            border-radius: 50%;
        }
        &::-webkit-scrollbar-thumb{
            
            background-color: grey;
            border-radius: 20px;
        }

        
    }
    &__send {
        margin-top: 30px;
        padding: 5px;
        box-shadow: #000 0px 2px 8px 0px;
        border-radius: 10px;
        min-height: 40px;
        max-height: 40px;
        overflow-y: auto;
    }
}
.chat-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &__message {
    }
}
.message {
    max-width: 230px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-shadow: #000 0px 2px 8px 0px;
    border-radius: 20px;
    padding: 10px;

    &__not-mine {
        align-self: start;
    }

    &__text {
        font-size: toRem(14);
    }

    &__date {
        font-size: toRem(12);
        font-weight: 500;
        
    }

    &__mine {
        align-self: end;
        & .message{
            &__text{
                text-align: right;
            }
            &__date{
                text-align: right;
            }
        }
    }
}
.send-text {
    position: relative;
    &__input{
        display: block;
        width: calc(100% - 40px);
        height: 30px;
        padding: 2px;
        &:focus-visible{
            outline: none !important;
            border: none !important;
            box-shadow: none !important;
        }
    }
    &__send-btn{
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate(0,-50%);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        transition: all .3s ease-in-out;
        svg{
            width: 100%;
            height: 100%;
            
        }
    }
}








// стилі при наведенні курсора
@media (hover:hover){
    .popup{
        &__close{
            svg{
                &:hover{
                    transform: scale(1.05);
                    transition: transform .3s ease-in-out;
                }
                
            }
        }
        &__btn-continue{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
        }
        &__btn-ok,&__btn-cancel{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }

        }
    }
    .create-add-popup{
        &__close{
            svg{
                &:hover{
                    transform: scale(1.05);
                    transition: transform .3s ease-in-out;
                }
                
            }
        }
        &__btn-continue{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
        }
        &__btn-ok,&__btn-cancel{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }

        }
    }
    .archive-popup{
        &__close{
            svg{
                &:hover{
                    transform: scale(1.05);
                    transition: transform .3s ease-in-out;
                }
                
            }
        }
        &__btn-ok,&__btn-cancel{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }

        }
    }
    .approve-advertisement-one{
        &__close{
            svg{
                &:hover{
                    transform: scale(1.05);
                    transition: transform .3s ease-in-out;
                }
                
            }
        }
    }
    .approve-advertisement-multi{
        &__close{
            svg{
                &:hover{
                    transform: scale(1.05);
                    transition: transform .3s ease-in-out;
                }
                
            }
        }
    }
    .create-view{
        &__close{
            svg{
                &:hover{
                    transform: scale(1.05);
                    transition: transform .3s ease-in-out;
                }
                
            }
        }
        &__create{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
        }
    }
    .data-create-view{
        &__photo-upload{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
            
        }
        &__approve{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
            

        }
        &__reject{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
        }
    }
    .delete-popup{
        &__close{
            svg{
                &:hover{
                    transform: scale(1.05);
                    transition: transform .3s ease-in-out;
                }
                
            }
        }
        &__btn-ok,&__btn-cancel{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
            
        }
    }
    .create-chat{
        &__close{
            svg{
                &:hover{
                    transform: scale(1.05);
                    transition: transform .3s ease-in-out;
                }
                
            }
        }
        &__btn-ok,&__btn-cancel{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
            
        }
    }
    .view-card{
        &__close{
            svg{
                &:hover{
                    transform: scale(1.05);
                    transition: transform .3s ease-in-out;
                }
                
            }
        }
    }
    .edit-configuration{
        &__save{
            &:hover{
                transition: all .3s ease-in-out;
                box-shadow: #000 0px 2px 8px 0px;
            }
        }
        &__close{
            svg{
                &:hover{
                    transform: scale(1.05);
                    transition: transform .3s ease-in-out;
                }
                
            }
        }
    }
    .create-chat{
        &__btn-continue{
            &:hover{
                transition: all .3s ease-in-out;
                box-shadow: #000 0px 2px 8px 0px;
            }
            
        }
    }
    .create-adv-admin{
        &__submit{
            &:hover{
                transition: all .3s ease-in-out;
                box-shadow: #000 0px 2px 8px 0px;
            }
        }
    }
    .create-moderator{
        &__close{
            svg{
                &:hover{
                    transform: scale(1.05);
                    transition: transform .3s ease-in-out;
                }
                
            }
        }
        &__btn-ok,&__btn-cancel{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
            
        }
    }
    .multi-slide {

        &__arrow{
            &:hover{
                svg{
                    transform: scale(1.1);
                    transition: transform .3s ease-in-out;
                }
            }
            
            
        }
        &__disabled{
            &:hover{
                svg{
                    transform: none;
                }
            }
            
        }
    }
    .add-transaction-admin{
        &__close{
            svg{
                &:hover{
                    transform: scale(1.05);
                    transition: transform .3s ease-in-out;
                }
                
            }
        }
        &__btn-continue{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
        }
        &__btn-ok,&__btn-cancel{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }

        }
        &__btn-submit{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
        }
    }
    .mass-mail-popup{
        &__file-upload{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
        }
        &__btn-send{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
        }
    }
    .send-text{
        &__send-btn{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;                
                transition: all .3s ease-in-out;
                
            }
        }
    }
}