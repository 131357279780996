.funds {
    padding: 20px 0px;

    &__container {
        padding-left: 300px;
        position: relative;
    }

    &__title {
    }

    &__payment {
        margin-top: 20px;
        
    }
    &__additional-payment {
       
    }

    &__history {
    }
}
//payment block
.payment {
    height: 350px;
    width: 700px;
    position: absolute;
    left: 50%;
    top: 30px;
    transform: translate(-25%,0);
    transition: left .3s ease-in-out;    
    &.close{        
        left: -700px;
        transition: left .3s ease-in-out;

    }
    &__title {
    }

    &__initial-data {
        margin-top: 30px;
    }
}

.initial-data {
    gap: 40px;
    display: flex;
    justify-content: flex-start;
    
    

    &__body {
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        gap: 30px;
    }

    &__amount {
        width: 500px;
    }
    &__info{
        width: 500px;
        display: flex;
        align-items: center;
        gap: 20px;
        border-radius: 25px;
        padding: 10px 15px;
        font-size: toRem(17);
        font-weight: 500;
        svg{
            width: 70px;
            height: 70px;
        }
        span{
            display: inline-block;
            
            
        }
    }

    &__btn {
        align-self: flex-start;
        padding: 10px 15px;
        font-weight: 500;
        font-size: toRem(16);
        border: 1px solid #000;
        border-radius: 15px;
        color: #000;
        transition: box-shadow .3s ease-in-out;
    }
}

.wallet {

    &__input {
        display: block;
        width: 100%;
        padding: 10px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
    }
}
.amount {
    &::before{
        content:'$';
        font-size: toRem(16);
        line-height: 100%;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translate(0,-50%);
    }
    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
    }

}

//additional payment block
.additional-payment {
    margin-top: 20px;
    position: absolute;
    left: -700px;
    height: 350px;
    width: 700px;
    transition: left .3s ease-in-out;
    &.active{
        left: 50%;
        transform: translate(-25%,0);
        transition: left .3s ease-in-out;
    }
    &__title {
    }

    &__add-data {
        margin-top: 20px;
    }
    &__buttons{
        margin-top: 15px;
        display: flex;
        justify-content: flex-start;
        gap: 40px;
    }
}
.add-data {
    display: flex;
    gap: 30px;

    &__info {
        flex: 0 0 65%;
    }

    &__qr {
        flex: 1 0 25%;
    }
}
.info-data {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &__time-money{
        display: flex;
        gap: 40px;
        align-items: center;
    }
    &__time {        
        padding: 10px;
        border: 1px solid #000;
        box-shadow: #000 0px 2px 8px 0px;
        color: #000;
        font-weight: 500;
        font-size: toRem(18);
        line-height: 100%;
        width: 100px;
        border-radius: 15px;
        display: flex;
        gap: 10px;
        align-items: center;
        svg{
            width: 18px;
            height: 18px;
            path{
                stroke: #000;
            }
        }
    }
    &__amount-usd{
        padding: 10px;
        border: 1px solid #000;
        box-shadow: #000 0px 2px 8px 0px;
        color: #000;
        font-weight: 500;
        font-size: toRem(18);
        line-height: 100%;
        width: 100px;
        border-radius: 15px;
        text-align: center;
    }

    &__amount {
        input{
            display: block;
        width: 100%;
        padding: 10px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        }
    }

    &__from-wallet {
        input{
            display: block;
            width: 100%;
            padding: 10px;
            font-size: toRem(16);
            line-height: 100%;
            border: 1px solid #000;
            color: $mainColor;
            transition: all 0.5s ease;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: 8px;
        }
        
    }

    &__to-wallet {
        input{
            display: block;
            width: 100%;
            padding: 10px;
            font-size: toRem(16);
            line-height: 100%;
            border: 1px solid #000;
            color: $mainColor;
            transition: all 0.5s ease;
            text-overflow: ellipsis;
            overflow: hidden;
            border-radius: 8px;
        }
        
    }
}
.qr-code{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    box-shadow: #000 0px 2px 8px 0px;
    border-radius: 25px;
    &__img{
        width: 140px;
        height: 140px;
    }
}
.buttons{
    &__cancel{
        padding: 10px 15px;
        font-weight: 500;
        font-size: toRem(16);    
        border: 1px solid #000;    
        border-radius: 15px;
        color: #000;
        transition: box-shadow .3s ease-in-out;

    }
    
}

//table history transactions block

.history {
    padding-top: 370px;

    &__title {
    }

    &__table {
    }
}

@media(hover:hover){
    .initial-data{
        &__btn{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: box-shadow .3s ease-in-out;
            }
        }
    }
    .buttons{
        &__cancel{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: box-shadow .3s ease-in-out;
            }
        }
        &__submit{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: box-shadow .3s ease-in-out;
            }
        }
    }
    
}
