.advertisement-edit {
    padding: 20px 0px 20px;
    &__container {
        padding-left: 300px;
    }

    &__title {
    }

    &__create-adv {
        margin-top: 20px;
    }
    &__info{
        margin-top: 30px;
    }
    &__table {
        margin-top: 20px;
    }
    &__control-row{
        margin-top: 20px;
    }
}