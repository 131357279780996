.support {
    padding: 20px 0px;
    &__container {
        padding-left: 300px;
    }

    &__title {
    }
    &__create-chat{
        margin-top: 20px;
        border: 1px solid #000;
        border-radius: 25px;
        padding: 10px 15px;
        display: inline-block;
        top: 0px;
        right: 10px;
        transition: all .3s ease-in-out;
        font-size: toRem(20);
        font-weight: 500;
        line-height: 100%;    
    }
    &__table {
        margin-top: 20px;
    }
    &__paging{

    }
}
@media (hover:hover){
    .support{
        &__create-chat{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
        }
    }
}
