.advertisement {
    padding: 20px 0px;
    &__container {
        padding-left: 300px;
    }
    &__title {
    }
    &__create {
    }
    &__stat {
    }

    &__charts {
    }

    &__table {
        margin-top: 20px;
    }

    &__paging {
    }
}



@media (hover:hover){
    .create-add{
        &:hover{
            box-shadow: #000 0px 2px 8px 0px;
            transition: all .3s ease-in-out;
        }
        
    }
    
}
