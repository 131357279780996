.page-404 {
    &__container {
        max-width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 30px;
    }
    &__image {
        animation: gigle 4s linear infinite;
        svg{
            width: 60px;
            height: 60px;
        }
    }

    &__title {
        font-size: toRem(40);
        font-weight: 600;
        line-height: 100%;

    }

    &__text {
        font-size: toRem(26);
        font-weight: 500;
        line-height: 100%;  
    }

    &__link {
        padding: 10px 15px;
        border: 1px solid #000000;
        border-radius: 25px;
        font-size: toRem(20);
        font-weight: 500;
        line-height: 100%;
    }
}
@media(hover:hover){
    .page-404{
        &__link{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
        }
    }
}
@keyframes gigle {
    0%{
        transform: rotate(0deg);
    }
    5%{
        transform: rotate(-15deg);
    }
    10%{
        transform: rotate(0deg);
    }
    15%{
        transform: rotate(15deg);
    }
    20%{
        transform: rotate(0deg);
    }
    
}