//графіки
.charts {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(2 ,1fr);
    column-gap: 15px;
    row-gap: 25px;
    &__line-chart {
        border: 1px solid #000;
        box-shadow: #000 0px 2px 8px 0px;
        border-radius: 15px;
        height: 400px;
    }

    &__pie-chart {
        border: 1px solid #000;
        box-shadow: #000 0px 2px 8px 0px;
        border-radius: 15px;
        height: 400px;
        padding: 5px;
        overflow: hidden;
        & h3{
            font-size: toRem(14);
            font-weight: 500;
            margin-bottom: 5px;
        }
        & :nth-child(3){
            height: 348px;
            max-height: 348px;
        }     
        & text{
            font-size: toRem(12);
            width: 50px !important;
            max-width: 50px;
            overflow: hidden;
            white-space: normal;

        }
        & path{
            max-width: 30px;
        }
    }
    &__date-picker{
        margin-bottom: 5px;

    }
}
.date-picker{
    display: flex;
    justify-content: space-around;
    gap: 40px;
    &__start{

    }
    &__end{

    }
    &__start,&__end{
        border: 1px solid #000;
        transition: all .2s ease;
        padding: 2px 5px;
        border-radius: 15px;
        &:focus-visible{
            outline: none;
            box-shadow: #000 0px 2px 8px 0px;
            transition: all .2s ease;
        }
        
    }
}
@media(hover:hover){
    .date-picker{
        &__start,
        &__end{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .2s ease;
            }
        }
    }
}