@use "sass:math";

// Підключення міксинів ===========================================================================================================================================================================================================================================================================
//Підключення шрифту
@mixin font($font_name, $file_name, $weight, $style) {
	@font-face {
		font-family: $font_name;
		font-display: swap;
		src:
			url("../fonts/#{$file_name}.woff2") format("woff2"),
			url("../fonts/#{$file_name}.woff") format("woff");
		font-weight: #{$weight};
		font-style: #{$style};
	}
}
//Percent
@function percent($px, $from: 100) {
	$result: math.div($px, $from) * 100%;
	@return $result;
}
//REM
@function toRem($px, $current: 16) {
	$result: math.div($px, $current) + rem;
	@return $result;
}
//EM
@function toEm($px, $current: 16) {
	$result: math.div($px, $current) + em;
	@return $result;
}

//Currency
@mixin currency($sym) {
	&::after {
		content: "#{$sym}";
	}
}

// Grids
@mixin gridCards($type: fit, $min: 280px, $max: 1fr, $gap: 30px) {
	display: grid;
	gap: $gap;
	grid-template-columns: repeat(auto-#{$type}, minmax($min, $max));
}

// Адаптивна властивість (clamp)
@mixin adaptiveValue($property, $startSize, $minSize, $keepSize: 0, $widthFrom: $containerWidth, $widthTo: $minWidth) {
	@if ($startSize==0) {
		$startSize: 0.000001;
	}
	@if ($minSize==0) {
		$minSize: 0.000001;
	}

	// Для calc();
	$addSize: math.div($startSize - $minSize, 16);

	@if ($widthFrom == $containerWidth and $maxWidthContainer == 0) {
		$widthFrom: $maxWidth;
	}

	// Брейк-поїнти в EM
	$widthFromMedia: toEm($widthFrom);
	$widthToMedia: toEm($widthTo);

	// Формула плаваючого значення
	// Источник: https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/
	$slope: math.div(($startSize - $minSize), ($widthFrom - $widthTo));
	$yIntersection: -$widthTo * $slope + $minSize;
	@if ($yIntersection==0) {
		$yIntersection: 0.000001;
	}
	$flyValue: #{toRem($yIntersection)}" + " #{$slope * 100}vw;

	// Отримання значення властивості
	$propertyValue: #{"clamp(" toRem($minSize) "," $flyValue "," toRem($startSize) ")"};
	// Якщо негативні значення
	@if ($minSize > $startSize) {
		$propertyValue: #{"clamp(" toRem($startSize) "," $flyValue "," toRem($minSize) ")"};
	}

	// Встановлюємо значення за замовчуванням
	@if $keepSize != 1 and $keepSize != 3 {
		@media (min-width: $widthFromMedia) {
			#{$property}: toRem($startSize);
		}
	}
	// Адаптуємо розмір у проміжку між зазначеними ширинами в'юпорту
	@media (min-width: $widthToMedia) and (max-width: $widthFromMedia) {
		// Якщо підтримується clamp();
		@supports (#{$property}: $propertyValue) {
			#{$property}: $propertyValue;
		}
		// Якщо не підтримується clamp();
		@supports not (#{$property}: $propertyValue) {
			#{$property}: calc(#{toRem($minSize)} + #{$addSize} * (100vw - #{toRem($widthTo)}) / #{math.div($widthFrom, 16) - math.div($widthTo, 16)});
		}
	}
	@if $keepSize != 1 and $keepSize != 2 {
		@media (max-width: $widthToMedia) {
			#{$property}: toRem($minSize);
		}
	}
}


// Підключення шрифтів ===========================================================================================================================================================================================================================================================================
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
// Підключити, якщо є локальні файли шрифтів
//@import "fonts/fonts";

// Підключення іконкових шрифтів ==============================================================================================================================================================================================================================================================================
// Підключити, якщо є файл іконкового шрифту
//@import "fonts/icons";

// ============================================================================================================================================================================================================================================================================================================================================================================================
// Налаштування шаблону ============================================================================================================================================================================================================================================================================================================================================================================================
// ============================================================================================================================================================================================================================================================================================================================================================================================

// За замовчуванням шрифт ==============================================================================================================================================================================================================================================================================
$fontFamily: "Inter";
$fontSize: toRem(14); // де 14(px) - розмір шрифту за замовчуванням з макету

// Основні кольори
$mainColor: #000; // Колір шрифту за замовчуванням
$bluecolor: #0536b5;
$bluecolorhover:#06277d;
$pagebg:#f9fafc;
$textwhitecolor: #f9fafc;
$redcolor: #9e2218;
$redcolorhover: #78241d;

// Палітра кольорів
// @import "base/colors-palette";

// Налаштування адаптивної сітки ===============================================================================================================================================================================================================================================================================================

// Мінімальна ширина сторінки
$minWidth: 320;
// Ширина полотна (макету)
$maxWidth: 1920;
// Ширина обмежуючого контейнера (0 = немає обмеження)
$maxWidthContainer: 1170;
// Загальний відступ у контейнера
// (30 = по 15px ліворуч і праворуч, 0 = немає відступу)
$containerPadding: 30;

// Ширина спрацьовування першого брейкпоінту
$containerWidth: $maxWidthContainer + $containerPadding;

// Брейк-поїнти
$pc: toEm($containerWidth); // ПК, ноутбуки, деякі планшети у горизонтальному положенні
$tablet: toEm(991.98); // Планшети, деякі телефони в горизонтальному положенні
$mobile: toEm(767.98); // Телефони L
$mobileSmall: toEm(479.98); // Телефони S

// Тип адаптива:
// 1 = чуйність (у контейнера немає брейкпоінтів),
// 2 = по брейк-поїнт (контейнер змінює свою ширину по брейк-поїнт)
$responsiveType: 1;

// ============================================================================================================================================================================================================================================================================================================================================================================================
// ============================================================================================================================================================================================================================================================================================================================================================================================

// Обнулення ============================================================================================================================================================================================== =============================================================================================== ===============================================================================================
* {
	padding: 0px;
	margin: 0px;
	border: 0px;
}
*,
*::before,
*::after {
	box-sizing: border-box;
}
*::before,
*::after {
	display: inline-block;
}
:focus,
:active {
	// outline: none;
}
a:focus,
a:active {
	// outline: none;
}
html,
body {
	height: 100%;
	min-width: $minWidth + px;
}
body {
	color: $mainColor;
	line-height: 1;
	font-family: $fontFamily;
	font-size: $fontSize;
	//text-rendering: optimizeLegibility;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
input,
button,
textarea {
	font-family: $fontFamily;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	background-color: transparent;
}

input,
textarea {
	width: 100%;
}

button,
select,
option {
	cursor: pointer;
}
a {
	display: inline-block;
	color: inherit;
	text-decoration: none;
}
ul li {
	list-style: none;
}
img {
	vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: inherit;
	font-size: inherit;
}


// Стилі тега BODY ============================================================================================================================================================================================================================================================================================================================================================================================
body {
	// Скролл заблоковано
	&.lock {
		overflow: hidden;
		touch-action: none;
		overscroll-behavior: none;
	}
	// Сайт завантажений
	.loaded & {
	}
}
// Оболонка wrapper ============================================================================================================================================================================================================================================================================================================================================================================================================================================
.wrapper {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	@supports (overflow: clip) {
		overflow: clip;
	}
	// Притискаємо footer
	> main {
		flex: 1 1 auto;
	}
	// Фікс для слайдерів
	> * {
		min-width: 0;
	}
}
.page{
	background-color: $pagebg;
}
// Обмежуючий контейнер ======================================================================================================================================================================================================================

// (i) Стилі будуть застосовуватись до
// всіх класів, що містять *__container
// Наприклад header__container, main__container і т.п.
// Сніппет (HTML): cnt

@if ($responsiveType==1) {
	// Чуйна
	[class*="__container"] {
		@if ($maxWidthContainer>0) {
			max-width: toRem($containerWidth);
			margin: 0 auto;
		}
		@if ($containerPadding>0) {
			@if ($maxWidthContainer>0) {
				padding: 0 toRem(math.div($containerPadding, 2));
			} @else {
				@include adaptiveValue("padding-left", math.div($containerPadding, 2), 15);
				@include adaptiveValue("padding-right", math.div($containerPadding, 2), 15);
			}
		}
	}
} @else {
	// Брейк-поїнтами
	[class*="__container"] {
		margin: 0 auto;
		@if ($maxWidthContainer>0) {
			max-width: toRem($containerWidth);
		} @else {
			@if ($containerPadding>0) {
				padding: 0 toRem(math.div($containerPadding, 2));
			}
		}
		@media (max-width: $pc) {
			max-width: toRem(970);
		}
		@media (max-width: $tablet) {
			max-width: toRem(750);
		}
		@media (max-width: $mobile) {
			max-width: none;
			@if ($containerPadding>0 and $maxWidthContainer>0) {
				padding: 0 toRem(math.div($containerPadding, 2));
			}
		}
	}
}

// Підключення базових стилів, шаблонів (заготівель) та допоміжних класів
// Для підключення/вимкнення конкретних стилів дивись base.scss
@import "base";

// Підключення стилів загальних елементів проекту
@import "common";

// Підключення стилів окремих блоків
@import "loginRegistrationForget";
@import "header";
@import "table";
@import "funds";

@import "advertisementList";
@import "stat";
@import "charts";
@import "popup";
@import "support";
@import "supportAdmin";
@import "advertisementConfiguration";
@import "advertisementEdit";
@import "advertisementView";
@import "admin-model";
@import "admin-createModers";
@import "advertisement-approve";
@import "users-admin";
@import "about-user";
@import "mass-mailing";
@import "footer";
@import "404";
@import "maintenance";
// Підключення стилів окремих сторінок
@import "home";
