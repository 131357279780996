.advertisement-view {
    padding: 20px 0px;

    &__container {
        padding-left: 300px;
    }

    &__title {
    }

    &__stat {
    }

    &__charts {
    }

    &__table {
    }

    &__paging {
    }
}
