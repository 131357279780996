.about-user {
    padding: 20px 0px 20px;
    &__container {
        padding-left: 300px;
    }

    &__title {
    }

    &__charts {
        margin-top: 20px;
    }
    &__add-funds {
        display: inline-block;
        margin-top: 20px;
        padding: 10px 15px;
        border: 1px solid #000;
        border-radius: 25px;
        font-size: toRem(20);
        font-weight: 500;
        cursor: pointer;
        transition: all .3s ease-in-out;
    }

    &__table {
        margin-top: 20px;
    }

    &__paging {
        margin-top: 20px;
    }
    & .table-create-view{
        top: 12px;
    }
    & .table__row{
        &:first-child{
            & .hesh{
                padding-left: 60px;
            }
        }
    }
    & .mass-mailing__table{
        margin-top: 20px;
    }
    & .support__table{
        margin-top: 20px;
    }
}
@media(hover:hover){
    .about-user{
        &__add-funds{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: all .3s ease-in-out;
            }
        }
    }
}