.header {

    &__container {
    }

    &__navigation {
        position: fixed;
        left: 0;
        top: 0;
        width: 280px;
        height: 100%;
        border-right: 1px solid #000;
        overflow-y: hidden;
        z-index: 5;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding: 10px 5px;
        box-shadow: #000 0px 2px 8px 0px;
    }

    &__logo {
    }

    &__title {
        margin-top: 40px;
        color: #000;
        text-align: center;
    }

    &__account-balance {
        margin-top: 20px;
    }

    &__links {
        margin-top: 20px;
        flex: 1 1 auto
    }
}
.account-balance {
    display: flex;
    border: 1px solid #000;
    box-shadow: #000 0px 2px 8px 0px;
    padding: 10px 15px;
    border-radius: 8px;
    justify-content: space-between;
    gap: 5px;
    align-items: center;


    &__info {
        flex: 0 0 80%;
        display: flex;
        flex-direction: column;
        line-height: 120%;
        width: 100%;
        
    }

    &__username {
        color: #000;
        font-size: toRem(14);
        line-height: 175%;
        font-weight: 500;
        max-width: 195px;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        word-spacing: 0px;
    }
    &__money {
        color: #000;
        font-size: toRem(14);
        line-height: 157%;
        font-weight: 500;
    }

    &__image {
        width: 30px;
        height: 30px;
        svg{
            width: 100%;
            height: 100%;
            path{
                stroke: #000;
            }
        }
    }
    
}
.money {
    display: flex;
    align-items: center;
    gap: 10px;
    
    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 2px;
    }
    &__refresh{
        width: 20px;
        height: 20px;
        transition: all .3s ease;
        svg{
            width: 100%;
            height: 100%;
        }
    }
}

.links-header {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__link {
        padding: 5px 15px;
        color: #000;
        font-size: toRem(18);
        font-weight: 700;
        line-height: 120%;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        svg{
            width: 30px;
            height: 30px;
            path,circle{
                stroke: #000;
            }
        }
        &:first-child{
            svg{
                path{
                    stroke: none;
                    fill: #000;
                }
                
            }
        }
    }
}
.no-stroke{
    stroke: none !important;

}

@media(hover:hover){
    .money{
        &__refresh{
            &:hover{
                transform: scale(1.05);
                transition: all .3s ease;
            }
        }
    }
    .links-header{
        &__link{
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                border-radius: 15px;
                
                transition: all .2s ease-in-out;
                svg{
                    path,circle{
                        
                    }
                }
            }
            &:first-child{
                &:hover{
                    svg{
                        path{
                            stroke: none;
                            
                        }
                    }
                }
                
            }
            &:nth-child(3){
                &:hover{
                    svg{ 
                                              
                    }
                }
                
            }
            
        }
    }
    
}