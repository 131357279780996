.admin-models {
    padding: 20px 0px 20px;
    &__container {
        padding-left: 300px;
    }

    &__title {
    }

    &__table {
        margin-top: 20px;
    }
    &__paging{
        margin-top: 20px;
    }
}