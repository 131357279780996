.login, .signup, .forget {
    background-color: #3b3b3b;
    min-height: 100vh;    
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &__container {
        max-width: 100%;
        height: 100%;
        padding: 50px 15px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__item {
    }
}
.login {

    &__container {
    }

    &__item {
    }
}
.item {
    width: 500px;
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr;
    padding: 25px 30px;
    border-radius: 25px;
    justify-items: center;

    &__welcome {
        font-size: toRem(32);
        font-weight: 500;
        line-height: 100%;
    }

    &__wrapper {
        width: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
    }
    &__about{
        font-size: toRem(16);
        line-height: 100%;
        text-align: center;
    }
    &__input {
        margin-top: 15px;
    }

    &__link {
        width: 186px;
        margin-top: 20px;
        display: inline;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        span{
            padding: 10px 15px;
            border: 1px solid #000;
            border-radius: 25px;
            transition: box-shadow .3s ease-in-out;
        }
    }

    &__btn {
        width: 100%;
        //margin-top: 20px;
        padding: 10px 15px;
        font-size: toRem(18);
        font-weight: 500;
        line-height: 100%;
        border: 1px solid #000;
        border-radius: 25px;
        transition: box-shadow .3s ease-in-out;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #000;
            bottom: -20px;
            left: 0;
            
        }
    }

    &__sign-up {
        margin-top: 40px;
        font-size: toRem(16);
        font-weight: 400;
        line-height: 100%;
        a{
            margin-left: 10px;
            font-size: toRem(18);
            font-weight: 500;
            transition: all .3s ease-in-out;
        }
    }
    &__log-in{
        margin-top: 20px;
        font-size: toRem(16);
        font-weight: 400;
        line-height: 100%;
        a{
            margin-left: 10px;
            font-size: toRem(18);
            font-weight: 500;
            transition: all .3s ease-in-out;
        }
    }
    &__back-login{
        text-align: center;
        margin-top: 20px;
        font-size: toRem(16);
        font-weight: 400;
        line-height: 100%;
        a{
            font-size: toRem(18);
            font-weight: 500;
            transition: all .3s ease-in-out;
        }
    }
    &__links{
        margin-top: 30px;
        
    }
}
.links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:40px;
    &__item {
        text-align: center;
        width: 200px;
        font-size: toRem(18);
        line-height: 100%;
        font-weight: 500;
        border: 1px solid #000;
        border-radius: 25px;
        transition: box-shadow .3s ease-in-out;
        padding: 10px 15px;
    }
}
.signup{
    & .links{
        gap: 0;
    }
}
.cr-wrapper *,
.cr-wrapper *::before,
.cr-wrapper *::after {
	box-sizing: content-box !important;
}

.cr-wrapper input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.cr-wrapper span {
	font-size: toRem(16);
    a{
        font-size: toRem(18);
        font-weight: 500;
        text-decoration: underline;
    }
}

.cr-wrapper {
	display: table;
	position: relative;
	padding-left: 30px;
	cursor: pointer;
	margin-bottom: 5px;
}

.cr-wrapper input[type="checkbox"] ~ .cr-input {
	position: absolute;
	top: 50%;
	left: 0;
	height: 20px;
	width: 20px;
	background: #FFFFFF;
	transition: background 250ms;
	border: 1px solid #000000;
	border-radius: 3px;
	transform: translate(0, -50%);
}

.cr-wrapper input[type="radio"] ~ .cr-input {
	position: absolute;
	top: 50%;
	left: 0;
	height: 20px;
	width: 20px;
	background: #FFFFFF;
	transition: background 250ms;
	border: 1px solid #000000;
	border-radius: 20px;
	transform: translate(0, -50%);
}

.cr-wrapper input[type="checkbox"] ~ .cr-input::after {
	content: "";
	position: absolute;
	display: none;
	left: 4px;
	top: 4px;
	width: 12px;
	height: 12px;
	transition: background 250ms;
	background-color: #FFFFFF;
	clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.cr-wrapper input[type="radio"] ~ .cr-input::after {
	content: "";
	position: absolute;
	display: none;
	left: 4px;
	top: 4px;
	width: 12px;
	height: 12px;
	border-radius: 20px;
	background: #FFFFFF;
	transition: background 250ms;
}

.cr-wrapper input:checked ~ .cr-input::after {
	display: block;
}

.cr-wrapper:hover input[type="checkbox"]:not([disabled]) ~ .cr-input,
.cr-wrapper input[type="checkbox"]:focus ~ .cr-input {
	background: #F4F4F4;
	border-color: #000000;
}

.cr-wrapper:hover input[type="radio"]:not([disabled]) ~ .cr-input,
.cr-wrapper input[type="radio"]:focus ~ .cr-input {
	background: #F4F4F4;
	border-color: #000000;
}

.cr-wrapper input[type="checkbox"]:checked ~ .cr-input {
	background: #000000;
	border-color: #000000;
}

.cr-wrapper input[type="radio"]:checked ~ .cr-input {
	background: #000000;
	border-color: #000000;
}

.cr-wrapper input[type="checkbox"]:disabled ~ .cr-input,
.cr-wrapper input[type="radio"]:disabled ~ .cr-input {
	opacity: 0.4;
	cursor: not-allowed;
}

.cr-wrapper input[type="checkbox"]:disabled ~ span,
.cr-wrapper input[type="radio"]:disabled ~ span {
	opacity: 0.4;
	cursor: not-allowed;
}

.cr-wrapper input[type="checkbox"]:disabled ~ .cr-input::after {
	background: #FFFFFF;
}

.cr-wrapper input[type="radio"]:disabled ~ .cr-input::after {
	background: #FFFFFF;
}

.cr-wrapper:hover input[type="checkbox"]:not([disabled]):checked ~ .cr-input,
.cr-wrapper input[type="checkbox"]:checked:focus ~ .cr-input {
	background: #000000;
	border-color: #000000;
}

.cr-wrapper:hover input[type="radio"]:not([disabled]):checked ~ .cr-input,
.cr-wrapper input[type="radio"]:checked:focus ~ .cr-input {
	background: #000000;
	border-color: #000000;
}



/////////

.email-item {
    width: 100%;
    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 50px;
    }
}
.password-item, .password-confirm-item {
    position: relative;
    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 50px;
    }
    &__image{
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 10px;
        width: 25px;
        height: 25px;
        transform: translate(0,-50%);

    }
}
.forgot {
}
@media(hover:hover){
    .item{
        &__link{
            span{
                &:hover{
                    
                    box-shadow: #000 0px 2px 8px 0px;
                    transition: box-shadow .3s ease-in-out;
                }
            }
            
        }
        &__btn{
            &:hover{                    
                box-shadow: #000 0px 2px 8px 0px;
                transition: box-shadow .3s ease-in-out;
            }
        }
        &__sign-up{
            a{
                &:hover{
                    text-decoration: underline;
                    transition: all .3s ease-in-out;
                }
            }
            
        }
        &__back-login{
            a{
                &:hover{
                    text-decoration: underline;
                    transition: all .3s ease-in-out;
                }
            }
        }
    }
    .links {
        &__item {
            &:hover{
                box-shadow: #000 0px 2px 8px 0px;
                transition: box-shadow .3s ease-in-out;
            }
            
        }
    }
}
