.support-admin {
    padding: 20px 0px;
    &__container {
        padding-left: 300px;
    }

    &__title {
    }

    &__table {
        margin-top: 20px;
    }

    &__paging {
    }
}

