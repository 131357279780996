.advertisement-approve {
    padding: 20px 0px;
    &__container {
        padding-left: 300px;
    }

    &__title {
    }

    &__table {
        margin-top: 20px;
    }

    &__control-row {
        margin-top: 20px;
    }
    & .nickname{
        flex: 0 0 200px;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}
