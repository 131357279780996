// Заголовки, загальні БЕМ блоки та інше...
//загаловки 
.title {
    font-weight: 600;
    font-size: toRem(36);
    text-align: center;

    &__small {
        font-weight: 500;
        font-size: toRem(24);
    }
}
//анімація інпутів
input[type=text]:focus-visible{
    outline:none;
    border: 1px solid #000;
    box-shadow: #000 0px 2px 8px 0px;
}
input[type=number]:focus-visible{
    outline:none;
    border: 1px solid #000;
    box-shadow: #000 0px 2px 8px 0px;
}
textarea:focus-visible{
    outline:none;
    border: 1px solid #000;
    box-shadow: #000 0px 2px 8px 0px;
}
.top-animation {
    position: relative;
    input{
        color: $mainColor;                    
        &:focus+label,
        input:focus-visible+label{
            font-size: toRem(14);
            font-weight: 600;
            background-color: $pagebg;
            transition: all 0.3s ease;         
        }        
    }
    textarea{
        &:focus+label,
        :focus-visible+label{
            font-size: toRem(14);
            font-weight: 600;
            background-color: $pagebg;
            transition: all 0.3s ease; 
            
        }
    }
    label{
        position: absolute;
        top: 0;
        left: 14px;
        font-size: toRem(16);
        transform: translate(0,-50%);
        color: #000;
        transition: all 0.3s ease;
        z-index: 1;
        background-color: $pagebg;
        

    }
    
}
//card presentation
.presentation {
    flex: 0 0 360px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    overflow: hidden;
    box-shadow: 0 1px 6px #20212447;

    &__image {
        width: 100%;
        height: 325px;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        

    }

    &__body {
        padding: 10px 5px 20px;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 10px;
        width: 100%;
    }

    &__name {
    }

    &__values {
    }

    &__description {
        width: 360px;
        padding: 0px 5px;
        p{
            font-size: toRem(16);
            line-height: 100%;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align-last: left;
        }
    }

    &__social {
    }
}
.name-presentation {
    display: flex;
    flex-direction: column;
    gap: 5px;
    &__nickname {
        font-size: toRem(16);
        font-weight: 500;
        line-height: 100%;
    }

    &__of-path {
        font-size: toRem(14);
        line-height: 100%;
    }
}
.values-presentation {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    &__likes {
        
    }

    &__photo {
    }

    &__video {
    }

    &__price {
    }
}
.item-values {
    display: flex;
    gap: 5px;
    align-items: center;
    &__image {
        width: 20px;
        height: 20px;
    }
    &__amount {
        font-weight: 500;
        line-height: 100%;
    }
}
.social {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: flex-start;
    &__link {
        svg{
            width: 20px;
            height: 20px;
        }
    }
}
//paging
.paging {
    margin-top: 15px;
    display: flex;
    gap: 30px;
    justify-content: flex-end;    
    &__left-arrow {
        cursor: pointer;
        
        width: 30px;
        height: 30px;
        svg{
            width: 30px;
            height: 30px;

        }
    }

    &__right-arrow {
        cursor: pointer;
        width: 30px;
        height: 30px;
        svg{
            width: 30px;
            height: 30px;
        }
    }
    &__disabled{
        svg{
            polyline{
                stroke: grey;
            }
        }
    }
}
//інформація
.info{
    span{
        border-radius: 25px;
        padding: 10px 15px;
        font-size: toRem(20);
        font-weight: 500;
        line-height: 100%;
        border: 1px solid #000;
        box-shadow: #000 0px 2px 8px 0px;
    }
}

.create-adv {
    display: flex;
    gap: 15px;
    &__presentation {
        
    }

    &__data {
    }
}

// data creation
.data-general {
    width: 100%;
    &__body {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        /* row-gap: 30px; */
        align-content:space-between;
        height: 100%;
    }
    &__select {
    }
    &__instagram {
    }

    &__tiktok {
    }

    &__twitter {
    }

    &__daily-budget {
    }

    &__click-cost {
    }
    
}
.gender-select {
    position: relative;
	width: 100%;
    z-index: 2;
    &__select-header {
        border: 1px solid #000;
        cursor: pointer;
        display: flex;
        padding: 10px 10px 10px 15px;
        border-radius: 8px;
        font-size: toRem(16);
        line-height: 100%;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 50px;
    }

    &__select-current {
        padding: 5px;
        font-size: toRem(16);
        line-height: 100%;
        display: flex;
        align-items: center;
    }

    &__select-icon {
    }

    &__select-body {
    }

    &__select-item {
    }
}
//solo select
.select {
    
    &__icon{
        img{
            width: 20px;
            height: 20px;
            transform: rotate(-90deg);
            transition: all 0.1s ease;
        }
    }
    &__header {
	cursor: pointer;
	display: flex;
    text-overflow: ellipsis;
    overflow: hidden;

    }

    &__current {
        img{
            width: 20px !important;
            height: 20px !important;
            cursor: pointer;
            position: absolute;
            right: 0;
            top:50%;
            transform: translate(0, -50%);
        }
    }

    &__icon {
        align-items: center;
        display: flex;
        flex-shrink: 0;
        justify-content: center;
        height: 20px;
        margin-left: auto;
        text-align: center;
        width: 20px;
        transition: all 0.1s ease;
        transform: rotate(-90deg);
    }

    &__body {
    border: 1px solid #000;
	border-top: 0;
    display: none;
	left: 0;
	position: absolute;
	right: 0;
	top: 100%;
    background-color: $textwhitecolor;
    max-height: 150px;
    overflow: auto;
    //z-index: 500;
    }

    
    &__item {
    cursor: pointer;
	font-size: 16px;
	line-height: 100%;
	padding: 10px;
    }
    &.is-active .select__body{       
        display: block;
    }
    &.is-active .select__icon{
        transform: rotate(90deg);
        transition: all 0.1s ease;
    }
    &.is-active .select__header{
        box-shadow: #000 0px 2px 8px 0px;
        border: 1px solid #000;
        outline: none;
    }
    &.is-active label{
        color: #000;
        font-size: toRem(14);
        font-weight: 600;
        transition: all 0.3s ease;
    }
}
//multiselect
.custom-select {
    display: flex;
    gap: 20px;
    width: 100%;
    &:active+label{
        font-size: toRem(14);
        font-weight: 600;
        background-color: #fff;
        transition: all 0.3s ease;
        color: $bluecolor;
    }
    &__body{
        position: relative;
        width: 100%;
        
    }
    &__select-box{
        height: 50px;
        max-height: 50px;
        padding: 10px 10px 10px 15px;
        
        border: 1px solid #000;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: border .3s ease;
    }
    &__options{
        display: none;
    position: absolute;
    width: 100%;
    background-color: #fff;
    border: 1px solid #000;
    border-top: none;
    max-height: 225px;
    overflow-y: auto;
    z-index: 5;
    box-shadow:0px 4px 8px rgba(0,0,0,0.1);
    }
}

.select-box{
    &__tags-input{

    }
    &__selected-options{
        display: flex;
        flex-wrap: wrap;
        font-size: toRem(16);
        margin-top: 0;
    }
    &__tag{
        border: 1px solid #000;
        color: #000;
        border-radius: 5px;
        margin-top: 2px;
        margin-right: 5px;
        padding: 2px 10px ;
        display: flex;
        align-items: center;
        font-size: toRem(16);
    }
    &__remove-tag{
        margin-left: 6px;
        cursor: pointer;
        width: 18px;
        height: 18px;
        display: flex;
            align-items: center;
            justify-content: center;
        img{
            width: 15px;
            height: 15px;
            
        }
        
    }
    &__arrow{
        svg{
            transform: rotate(-90deg);
            width: 20px;
            height: 20px;
            transition: transform .3s ease;
        }
    }
}
.open .select-box__arrow{
    svg{
        transform: rotate(90deg);
        transition: transform .3s ease;
    }
}
.open .custom-select__select-box{
    border: 1px solid #000;
    box-shadow: #000 0px 2px 8px 0px;
    transition: border .3s ease;
}
.custom-select__body.open ~ label{
    font-size: toRem(14);
        font-weight: 600;
        background-color: #fff;
        transition: all 0.3s ease;
        color: #000;
}
.open .custom-select__options{
    display: block;
}
.options{
    border: 1px solid #000;
    border-top: none;
    padding: 8px 0px;
    &__option-search-tags{
        background-color: #fff;    
        
        margin: 8px;
        position: relative;
    input{
        border: 1px solid #000;
        border-radius: 8px;
    }
    }
    &__search-tags{
        width: 100%;
    border: none;
    outline: none;
    padding: 8px;
    font-size: toRem(16);
    }
    &__clear{
        position: absolute;
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-size: toRem(16);
        font-weight: 600;
        padding: 0;
        top: 50%;
        left: auto;
        right: 15px;
        transform: translate(0,-50%);
        svg{
            width: 20px;
            height: 20px;
        }
    }
    &__item {
        &:not(:last-child){
            margin-bottom: 5px;
        }
        &.active{
            .options{
                &__plus {
                    display: none;
                }
            
                &__minus {
                    display: block;
                }
                &__item-body{
                    height: 100%;
                }
            }
        }
    }

    &__title {
        padding-left: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: toRem(18);
        
    }

    &__plus {
        width: 20px;
        height: 20px;
        display: block;
    }

    &__minus {
        width: 20px;
        height: 20px;
        display: none;
    }

    &__item-body {
        height: 0;
        overflow: hidden;
    }

    &__option {
    }

    &__option{
        padding: 5px 30px;
        cursor: pointer;
        font-size: toRem(16);
        &.active{
            color: #000;
            background-color: #f2f2f2;
            border-bottom: 1px solid #eaeaea;
        }
    }
    &__no-result-message{
        padding: 0px 0px 12px 12px;
    }
}
//insta input
.instagram {

    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 50px;
    }
}
//tiktok input
.tiktok {

    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 50px;
    }
}
//twitter input
.twitter {

    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 50px;
    }
}
//baily budget input
.daily-budget {
    &::before{
        content:'$';
        font-size: toRem(16);
        line-height: 100%;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translate(0,-50%);
    }
    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 50px;
    }
}
//click cost input
.click-cost {
    &::before{
        content:'$';
        font-size: toRem(16);
        line-height: 100%;
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translate(0,-50%);
    }
    &__input {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 15px;
        font-size: toRem(16);
        line-height: 100%;
        border: 1px solid #000;
        color: $mainColor;
        transition: all 0.5s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        border-radius: 8px;
        height: 50px;
    }
}

.control-row {

    &__body {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__save-btn {
    }

    &__paging {
        margin: 0 !important;
    }
}

// кнопка збереження змін

.save-btn {
    display: block;
    border-radius: 25px;
    padding: 10px 15px;
    font-size: toRem(20);
    font-weight: 500;
    line-height: 100%;
    border: 1px solid #000;
}


@media(hover:hover){
    .save-btn{
        &:hover{
            box-shadow: #000 0px 2px 8px 0px;
            transition: all .3s ease-in-out;
        }
    }
}