.table {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    &__row {
        height: 50px;
        display: flex;
        align-items: center;
        gap: 5px;
        border-bottom: 1px solid #000;
        &:first-child{
            & .hesh{
                text-decoration: none;
                text-align-last: left;
                padding-left:40px;
            }
        }
    }
    &__create-view{        
        
    }
    &__create-adv {
    }
    &__create-chat{
        
    }
    &__create-admin-adv {
    }
    &__create-moderator {
    }
    &__check-advertisement {
    }
    &__create-mass-mail {
    }

    &__item {
        padding: 2px 0px;
        text-align: center;
        font-weight: 500;
        font-size: toRem(14);
    }

    
}
//general style of bytton create
.create-btn{
    border-radius: 50%;
    padding: 5px;
    border: 1px solid #00b1f5;
    position: absolute;
    top: 10px;
    left: 5px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;
    svg{
        width: 15px;
        height: 15px;
        path{
            stroke: #00b1f5;
            transition: all .3s ease;
        }
    }
}
.approve-btn{
    border-radius: 50%;
    padding: 5px;
    border: 1px solid #00b1f5;
    position: absolute;
    top: 10px;
    left: 5px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;
    svg{
        width: 20px;
        height: 20px;
        stroke: #00b1f5;
        transition: all .3s ease;   
        
    }
}
//btn create view

.row-table-first {
}
.pause-play{
    &__pause{
        display: none;
    }
    &__play{
        display: block;
        width: 20px;
        height: 20px;

    }
    &.active{
        .pause-play__pause{
            display: block;
        }
        .pause-play__play{
            display: none;
        }
    }
    
}
.date {
    width: 140px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.hesh {
    width: 600px;
    text-decoration: underline;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    
    
}
.declared {
    width: 140px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.hesh-link {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: toRem(14);

}

.actions{
    flex: 0 0 130px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    &__view{
        width: 20px;
        height: 20px;
        cursor: pointer;

    }
    &__edit{
        width: 20px;
        height: 20px;
        cursor: pointer;

    }
    &__pause-play{
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    &__archive{
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    &__delete {
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    &__chat{
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    &__chat-view{
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
    &__refresh{
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

.avatar{
    flex:0 0 50px;    
    img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    
    }
}
.nickname {
    flex: 0 0 160px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.create-date {
    flex: 0 0 110px;
}
.clicks {
    flex: 0 0 80px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.clicks-total {
    flex: 0 0 80px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.cpc {
    flex: 0 0 80px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.budget {
    flex: 0 0 80px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.spent {
    flex: 0 0 80px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.photo {
    flex:0 0 100px;
    
    img{
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        
    }
}
.destination {
    flex: 1 0 200px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.trial {
    flex: 1 0 200px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.status{
    flex: 1 0 50px;
    &__confirmed{
        width: 20px;
        height: 20px;
    }
    &__unconfirmed{
        width: 20px;
        height: 20px;        
    }
    &__archived{
        width: 20px;
        height: 20px;
    }
    &__waiting{
        width: 20px;
        height: 20px;
    }
}
.chat-date-creation {
    flex: 1 0 100px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.subject {
    flex: 1 0 250px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.new-massage {
    flex: 1 0 50px;
    span{
        font-size: toRem(14);
        font-weight: 500;
        line-height: 100%;
        padding: 5px;
        border-radius: 50%;
        border: 1px solid #000;
    }
}
.from-who{
    flex: 1 0 150px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.instagram-link,
.tiktok-link,
.twitter-link {
    flex: 1 0 70px;
    span{
        font-size: toRem(16);
        font-weight: 500;
    }
    a{
        display: block;
        svg{
            width: 30px;
            height: 30px;
            transition: transform .3s ease-in-out;
        }
    }
    
}

.email {
    flex: 1 0 250px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.password {
    flex: 1 0 250px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.country {
    flex: 1 0 70px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.support-request {
    flex: 0 0 70px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.transaction-number {
    flex: 0 0 80px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.total-sum {
    flex: 0 0 90px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.more-info {
    flex: 1 0 100px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;
    a{
        border-radius: 25px;
        padding: 5px 10px;
        font-size: toRem(14);
        font-weight: 500;
        line-height: 100%;
        border: 1px solid #000;
        transition: all .3s ease-in-out;   
    }
}
.username {
    flex: 0 0 200px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.email-subject {
    flex: 1 0 350px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    
}
.recipient {
    flex: 1 0 100px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
}
.table {

    &__row {
    }

    &__item {
    }
}

.add-funds-by-admin {
    border: 1px solid #000;
    box-shadow: #000 0px 2px 8px 0px;
    padding: 3px;
    border-radius: 5px;
}
.advertisement__table{
    & .actions{
        flex: 0 0 150px;
        gap: 5px;
    }
    & ,.nickname{
        flex: 0 0 150px
    }
}

@media (hover:hover){
    .table{
        &__row{
            &:hover{
                &:not(:first-child){
                    border-radius: 15px;
                    box-shadow: #000 0px 2px 8px 0px;
                    transform: scale(1.005);
                    transition: all .2s ease-in-out;
                }
                .new-massage{
                    span{
                        box-shadow: #000 0px 2px 8px 0px;
                        transition: all .3s ease-in-out;
                    }
                }
            }
            
        }
    }
    .create-btn{
        &:hover{
            background-color: #00b1f5;
            border: none;
            transition: all .3s ease;
            svg{
                path{
                    stroke: white;
                    transition: all .3s ease;
                    
                }
            }
        }
    }
    .approve-btn{
        &:hover{
            background-color: #00b1f5;
            border: none;
            transition: all .3s ease;
            svg{
                stroke: white;
                transition: all .3s ease;                
            }
        }
    }
    .instagram-link,
    .tiktok-link,
    .twitter-link{
       
            svg{
                &:hover{
                    transform: scale(1.1);
                    transition: transform .3s ease-in-out;
                }
                
            }
        
    }
    .more-info{
        &:hover{
            a{
                transform: scale(1.1);
                transition: all .3s ease-in-out;                    
            }
        }
        
    }
}